import React, { Fragment, useContext } from 'react'
import Link from 'next/link'
import { Grid, Typography, styledComponent, Box, Hidden, SvgIcon, WhiteTooltip } from '@hermes/web-components'
import useTranslateMessage from '../../../../hooks/useTranslateMessage'
import { ClickEventHandler } from '../../../../types/common'
import { AppDataContext } from '../../../../providers/AppData'

const textColor = '#11245A'

const Label = styledComponent(Typography)(() => ({
  color: textColor,
  fontSize: '13px',
  weight: 400
}))

const HorizontalDelimiter = styledComponent(Box)({
  width: '3.48px',
  height: '3.48px',
  backgroundColor: textColor,
  display: 'inline-flex',
  borderRadius: '50%',
  margin: '2px 8px'
})

const InfoPoint = ({ icon, label, skipIcons }: { icon: JSX.Element; label: JSX.Element; skipIcons?: boolean }) => {
  const IconWrapper = skipIcons ? Hidden : Fragment
  return (
    <Box
      sx={{
        display: 'flex',
        gap: '5px',
        alignItems: 'center'
      }}
    >
      <IconWrapper {...(skipIcons ? { smDown: true } : {})}>
        <Box sx={{ display: 'flex', justifyContent: 'center', minWidth: '32px' }}>{icon}</Box>
      </IconWrapper>
      <Box>{label}</Box>
    </Box>
  )
}

type ProfileDetailsProps = {
  yearsOfExperience?: number | string | null
  address: string
  addressTotal: number
  connections?: number | string | null
  endorsementsFrom?: string | null
  workingTime?: string | null
  specialties?: string[] | null
  specialtiesTotal?: number | string | null
  specialistsTotal?: number | string | null
  followers?: number | string | null
  isBookingAvailable?: boolean
  skipIcons?: boolean
  toggleBookingModal?: ClickEventHandler
}

const ProfileDetails = ({
  yearsOfExperience,
  address,
  addressTotal,
  connections,
  endorsementsFrom,
  isBookingAvailable,
  skipIcons,
  toggleBookingModal,
  workingTime,
  specialties,
  specialtiesTotal,
  specialistsTotal,
  followers
}: ProfileDetailsProps) => {
  const { isRtl } = useContext(AppDataContext)
  const translate = useTranslateMessage()

  return (
    <Grid container direction="column" sx={{ gap: '10px' }}>
      {isBookingAvailable ? (
        <InfoPoint
          skipIcons={skipIcons}
          icon={
            <Box
              onClick={toggleBookingModal}
              sx={{ width: '12px', height: '12px', cursor: 'pointer' }}
              bgcolor="#00BF7C"
              borderRadius="50%"
            />
          }
          label={
            <Label onClick={toggleBookingModal} sx={{ textDecoration: 'underline', cursor: 'pointer' }}>
              {translate('profile.intro.live_bookings_available')}
            </Label>
          }
        />
      ) : null}

      {yearsOfExperience ? (
        <InfoPoint
          skipIcons={skipIcons}
          icon={
            <SvgIcon
              viewBox="0 0 18 19"
              sx={{
                width: '16px',
                height: '16px'
              }}
            >
              <use stroke={textColor} href={'#shield-man-icon'} />
            </SvgIcon>
          }
          label={<Label>{translate('years_experience', { total: yearsOfExperience })}</Label>}
        />
      ) : null}

      {address ? (
        <InfoPoint
          skipIcons={skipIcons}
          icon={
            <SvgIcon
              viewBox="0 0 16 16"
              sx={{
                ml: 0,
                width: '16px',
                height: '20px'
              }}
            >
              <use href={'#location-pin-icon'} />
            </SvgIcon>
          }
          label={
            <Box>
              <Link href={'#locations'}>
                {addressTotal > 1 ? (
                  <Box>
                    <Label display="inline">{`${address} `}</Label>
                    <Label display="inline">
                      <span>(</span>
                      <span style={{ cursor: 'pointer', textDecoration: 'underline' }}>
                        {translate('plus_count_more', { count: addressTotal - 1 })}
                      </span>
                      <span>)</span>
                    </Label>
                  </Box>
                ) : (
                  <Label sx={{ cursor: 'pointer', textDecoration: 'underline' }} display="inline">
                    {address}
                  </Label>
                )}
              </Link>
            </Box>
          }
        />
      ) : null}

      {connections ? (
        <InfoPoint
          skipIcons={skipIcons}
          icon={
            <SvgIcon
              viewBox="0 0 20 20"
              sx={{
                ml: 0,
                width: '20px',
                height: '20px'
              }}
            >
              <use stroke={textColor} href={'#man-icon'} />
            </SvgIcon>
          }
          label={<Label>{translate('connections_in_healthcare', { total: connections })}</Label>}
        />
      ) : null}

      {endorsementsFrom ? (
        <InfoPoint
          skipIcons={skipIcons}
          icon={
            <SvgIcon
              viewBox="0 0 37 25"
              sx={{
                width: '32px'
              }}
            >
              <use fill={'#91A8D6'} href={'#endorsement-group-icon'} />
            </SvgIcon>
          }
          label={
            <Box>
              <Link href={'#endorsements'}>
                <Label display="inline" sx={{ cursor: 'pointer', textDecoration: 'underline' }}>
                  {translate('profile.intro.skill_endorsed')}
                </Label>
              </Link>
              <Label display="inline"> {endorsementsFrom}</Label>
            </Box>
          }
        />
      ) : null}

      {workingTime ? (
        <InfoPoint
          skipIcons={skipIcons}
          icon={
            <SvgIcon
              viewBox="0 0 14 14"
              sx={{
                width: '16px',
                height: '16px',
                color: 'transparent'
              }}
            >
              <use stroke={textColor} href={'#clock-icon'} />
            </SvgIcon>
          }
          label={<Label>{workingTime}</Label>}
        />
      ) : null}

      {specialties?.length && specialtiesTotal ? (
        <InfoPoint
          skipIcons={skipIcons}
          icon={
            <SvgIcon
              viewBox="0 0 19 19"
              sx={{
                ml: 0,
                width: '22px',
                height: '22px',
                color: 'transparent'
              }}
            >
              <use stroke={textColor} href={'#rosette-icon'} />
            </SvgIcon>
          }
          label={
            <Box>
              <Box display="inline">
                <Label display="inline">{translate('profile.specialties')}: </Label>
                {specialties.map((specialty, key) => (
                  <>
                    <Label key={key} display="inline">
                      {specialty}
                    </Label>
                    <HorizontalDelimiter />
                  </>
                ))}
              </Box>
              <Link href={'#specialties'}>
                <Label display="inline" sx={{ cursor: 'pointer', textDecoration: 'underline' }}>
                  {translate('view_all', { count: specialtiesTotal })}
                </Label>
              </Link>
            </Box>
          }
        />
      ) : null}

      {specialistsTotal ? (
        <InfoPoint
          skipIcons={skipIcons}
          icon={
            <SvgIcon
              viewBox="0 0 16 16"
              sx={{
                ml: 0,
                width: '18px',
                height: '18px',
                color: 'transparent'
              }}
            >
              <use stroke={textColor} href={'#users-icon'} />
            </SvgIcon>
          }
          label={
            <Box>
              <Link href={'#specialists'}>
                <Label display="inline" sx={{ cursor: 'pointer', textDecoration: 'underline' }}>
                  {specialistsTotal} {translate('specialists.disclaimer', { count: specialistsTotal })}
                </Label>
              </Link>
            </Box>
          }
        />
      ) : null}

      {followers ? (
        <InfoPoint
          skipIcons={skipIcons}
          icon={
            <SvgIcon
              viewBox="0 0 20 20"
              sx={{
                ml: 0,
                width: '20px',
                height: '20px'
              }}
            >
              <use stroke={textColor} href={'#man-icon'} />
            </SvgIcon>
          }
          label={
            <WhiteTooltip
              isRtl={isRtl}
              sx={{
                '& .MuiTooltip-tooltip': {
                  height: 'auto',
                  width: 'auto'
                }
              }}
              title={translate('healthcare_followers.disclaimer', { count: followers })}
            >
              <Box>
                <Label>
                  {followers} {translate('healthcare_followers', { count: followers })}
                </Label>
              </Box>
            </WhiteTooltip>
          }
        />
      ) : null}
    </Grid>
  )
}

export default ProfileDetails
