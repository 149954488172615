import React from 'react'
import DefaultVariant from './default'
import MinimalVariant from './minimal'
import { StarsComponentProps, Variants } from './types'

const StarsComponent = ({ variant, ...props }: StarsComponentProps & { variant?: Variants }) => {
  if (variant === Variants.minimal) {
    return <MinimalVariant {...props} />
  }

  return <DefaultVariant {...props} />
}

export default StarsComponent
