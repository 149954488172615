import { ProfileReviewStatistic } from '../../../../types'

export type StarsComponentProps = {
  expanded?: boolean
  averageRating: number
  review?: ProfileReviewStatistic | null
  toggleExpandedContent?: () => void
}

export enum Variants {
  default,
  minimal
}
