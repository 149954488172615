import React from 'react'
import { StyledPlayVideoButton } from '../../styles'
import { Button, Grid, PlayCircleIcon, useTheme, styledComponent } from '@hermes/web-components'
import { TEST_IDS } from '../../../../../__tests__/testUtils/constants'
import getImageWithWWW from '../../../../../utils/getImageWithWWW'
import { getInitialsFromFullName } from '../../variants/default'
import useTranslateMessage from '../../../../../hooks/useTranslateMessage'
import {
  AvatarContainerVariant1,
  AvatarContainerVariant2,
  AvatarVariant1,
  AvatarVariant2,
  TypographyAvatarVariant1,
  TypographyAvatarVariant2
} from './styles'
import { ProfileIntroProps } from '../../../../../types'

export enum Variants {
  VARIANT1 = 'variant1',
  VARIANT2 = 'variant2'
}

const useAvatarConfig = (variant: Variants = Variants.VARIANT1) => {
  const variant1 = {
    Avatar: AvatarVariant1,
    AvatarContainer: AvatarContainerVariant1,
    TypographyAvatar: TypographyAvatarVariant1,
    PlayVideoButton: StyledPlayVideoButton
  }

  switch (variant) {
    case Variants.VARIANT1: {
      return variant1
    }
    case Variants.VARIANT2: {
      return {
        Avatar: AvatarVariant2,
        AvatarContainer: AvatarContainerVariant2,
        TypographyAvatar: TypographyAvatarVariant2,
        PlayVideoButton: styledComponent(StyledPlayVideoButton)(({ theme }) => ({
          [theme.breakpoints.down('sm')]: {
            '&>svg': {
              margin: '-12px'
            }
          }
        }))
      }
    }
    default: {
      return variant1
    }
  }
}

type AvatarProps = Pick<ProfileIntroProps, 'name' | 'image' | 'title' | 'introVideoAvailable'> & {
  variant?: Variants
  isEntryOrUnclaimed?: boolean
  handleOpenVideoPlayer?: () => void
  introVideoAvailable?: boolean
}

const Avatar = ({
  variant,
  isEntryOrUnclaimed,
  handleOpenVideoPlayer,
  title,
  image,
  name,
  introVideoAvailable
}: AvatarProps) => {
  const theme = useTheme()
  const translate = useTranslateMessage()

  const imageWithWWW = getImageWithWWW(image)
  const initials = getInitialsFromFullName(name)

  const { Avatar, AvatarContainer, TypographyAvatar, PlayVideoButton } = useAvatarConfig(variant)

  return (
    <Grid>
      <AvatarContainer item maxHeight="min-content" position="relative" mr={2}>
        <Button
          onClick={handleOpenVideoPlayer}
          variant="ghost"
          sx={{
            padding: 0
          }}
        >
          <Avatar
            width={104}
            height={104}
            alt={title || ''}
            src={!isEntryOrUnclaimed ? imageWithWWW : ''}
            preload
            sx={{
              backgroundColor: !isEntryOrUnclaimed && image ? 'transparent' : 'blue.200'
            }}
          >
            <TypographyAvatar>{initials}</TypographyAvatar>
          </Avatar>
        </Button>
        {introVideoAvailable && (
          <PlayVideoButton
            aria-label={translate('media_center.introduction_video')}
            variant="ghost"
            onClick={handleOpenVideoPlayer}
            data-testid={TEST_IDS.PROFILE_INTRO_VIDEO_BUTTON}
            sx={theme?.custom?.SearchProfileCard?.profileAvatarContainer ?? {}}
          >
            <PlayCircleIcon
              sx={{
                width: '35px',
                height: '35px'
              }}
            />
          </PlayVideoButton>
        )}
      </AvatarContainer>
    </Grid>
  )
}

export default Avatar
