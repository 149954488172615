import { styledComponent } from '@hermes/web-components'
import { StyledAvatar, StyledAvatarContainer, StyledTypographyAvatar } from '../../styles'

export const AvatarContainerVariant1 = styledComponent(StyledAvatarContainer)({})

export const AvatarContainerVariant2 = styledComponent(StyledAvatarContainer)(({ theme }) => ({
  borderRadius: '8px',
  width: '217px',
  height: '217px',
  [theme.breakpoints.down('sm')]: {
    width: '97px',
    height: '97px'
  }
}))

export const AvatarVariant1 = styledComponent(StyledAvatar)({})

export const AvatarVariant2 = styledComponent(StyledAvatar)(({ theme }) => ({
  borderRadius: '8px',
  border: 'solid thin #BFCDF6',
  width: '217px',
  height: '217px',
  backgroundColor: '#FFFFFF !important',
  [theme.breakpoints.down('sm')]: {
    width: '97px',
    height: '97px'
  }
}))

export const TypographyAvatarVariant1 = styledComponent(StyledTypographyAvatar)({})

export const TypographyAvatarVariant2 = styledComponent(StyledTypographyAvatar)({
  fontFamily: 'ES Klarheit Kurrent',
  fontWeight: 200,
  color: '#1A3586'
})
