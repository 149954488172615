import React, { useContext, useMemo } from 'react'
import { Box, Rating, Typography, Link } from '@hermes/web-components'
import { StarsComponentProps } from './types'
import useTranslateMessage from '../../../../hooks/useTranslateMessage'
import { AppDataContext } from '../../../../providers/AppData'

const Minimal = ({ averageRating, review, toggleExpandedContent }: StarsComponentProps) => {
  const translate = useTranslateMessage()

  const { locale } = useContext(AppDataContext)

  const formattedReviewText = useMemo(() => {
    const isGermany = locale.includes('de')
    const reviewText = translate('reviews.reviews')

    return isGermany ? reviewText : reviewText.toLowerCase()
  }, [locale, translate])

  const href = '#reviews'
  const color = '#051851'
  const formatRating = averageRating === 0 ? '-' : averageRating
  return (
    <Link sx={{ textDecoration: 'none' }} href={href}>
      <Box display="flex" gap="5px" alignItems="center">
        <Typography sx={{ fontSize: '24px', fontWeight: 500, color }}>{formatRating}</Typography>
        <Typography sx={{ fontSize: '13px', fontWeight: 500, color, paddingTop: '4px' }}>/5</Typography>
        <Rating value={averageRating} sx={{ fontSize: 24 }} size={'low-medium'} readOnly />
        <Link component={'span'} sx={{ color }} href={href} onClick={toggleExpandedContent}>
          ({review?.reviewsTotal ?? 0} {formattedReviewText})
        </Link>
      </Box>
    </Link>
  )
}

export default Minimal
