import { useContext } from 'react'
import { ProfileIntroProps } from '../../../types'
import { useFeatureFlags } from '../../../hooks/useFeatureFlags'
import { AppDataContext } from '../../../providers/AppData'
import { TENANTS } from '../../../constants/global'
import DefaultVariant from './variants/default'
import MinimalVariant from './variants/minimal'

function ProfileIntro(props: ProfileIntroProps) {
  const { locale } = useContext(AppDataContext)

  const { newPersistentPanelEnabled } = useFeatureFlags()

  if (locale === TENANTS.WPA) {
    return <DefaultVariant {...props} />
  }

  if (newPersistentPanelEnabled) {
    return <MinimalVariant {...props} />
  }

  return <DefaultVariant {...props} />
}

export default ProfileIntro
