import React, { useCallback, useContext, useMemo } from 'react'
import {
  Box,
  formatNumberWithPlus,
  Grid,
  Hidden,
  Typography,
  WhiteTooltip,
  useTheme,
  VerifiedCurvedIcon,
  UnverifiedCurvedIcon
} from '@hermes/web-components'
import { useTopLevelKeywords } from '../../../../../hooks/useKeywordsMapper'
import { AppDataContext } from '../../../../../providers/AppData'
import { getYearsDiff } from '../../../../../utils/date'
import {
  getAddressFromPractices,
  removeVideoConsultation,
  transformAddressToShortString,
  transformAddressToString
} from '../../../../../utils/address'
import { ProfileIntroProps } from '../../../../../types'
import { TopKeywordText } from '../../styles'
import { TEST_IDS } from '../../../../../__tests__/testUtils/constants'
import { getDefaultCountry } from '../../../../../constants/global'
import Avatar, { Variants as AvatarVariants } from '../../components/Avatar'
import ProfileDetails from '../../components/ProfileDetails'
import StarsComponent from '../../../../RatingBar/components/StarsComponent'
import { Variants as StarsVariants } from '../../../../RatingBar/components/StarsComponent/types'
import useTranslateMessage from '../../../../../hooks/useTranslateMessage'

function Minimal({
  name,
  suffix,
  title,
  image,
  keywords,
  yearsOfExperience,
  address,
  isPractice: isClinic,
  practices,
  isUnclaimed = false,
  isEntry = false,
  introVideoAvailable = false,
  setVideoPlayerVisibility = () => null,
  profileAnalytics,
  connectionsCount = 0,
  isBookingAvailable,
  review,
  recommendersString,
  workingTimeString,
  specialtiesList,
  specialtiesCount = 0,
  specialistsCount = 0,
  followersCount = 0,
  toggleBookingModal,
  hideRating
}: ProfileIntroProps) {
  const theme = useTheme()
  const translate = useTranslateMessage()
  const { language, locale, isRtl } = useContext(AppDataContext)

  const formattedConnectionsCount = useMemo(
    () => (isClinic ? formatNumberWithPlus(connectionsCount) : connectionsCount),
    [isClinic, connectionsCount]
  )
  const isBasicView = isEntry || isUnclaimed

  const userTitle = useMemo(() => {
    let ut = name
    if (title) {
      ut = `${title} ${ut}`
    }
    return ut
  }, [name, title])

  const topLevelKeywords = useTopLevelKeywords(keywords || [])

  const yearsOfExperienceValue = useMemo(() => getYearsDiff(yearsOfExperience || ''), [yearsOfExperience])

  const defaultCountry = getDefaultCountry(locale)

  const { address: transformedAddress, total: addressTotal } = useMemo(() => {
    const addressToTransform = address || getAddressFromPractices(practices)

    if (!addressToTransform) {
      return {
        address: defaultCountry,
        total: 0
      }
    }

    return address
      ? {
          address: transformAddressToString(addressToTransform, language) || defaultCountry,
          total: 1
        }
      : {
          address: transformAddressToShortString(addressToTransform, language) || defaultCountry,
          total: practices?.filter?.(removeVideoConsultation)?.length || 1
        }
  }, [address, practices, language, defaultCountry])

  const handleOpenVideoPlayer = useCallback(() => {
    if (introVideoAvailable) {
      if (window) {
        window.dataLayer.push({
          event: 'prf_pg_prf_vid_click',
          cont_prf_name: userTitle,
          ...profileAnalytics
        })
      }
      setVideoPlayerVisibility(true)
    }
  }, [introVideoAvailable, profileAnalytics, setVideoPlayerVisibility, userTitle])

  const onToggleBookingModal = useCallback(() => {
    toggleBookingModal?.('book')
    if (!isClinic) {
      window?.dataLayer?.push?.({
        event: 'prf_pg_cont_button',
        cont_prf_name: name,
        cont_prf_type: 'Specialist',
        cont_interaction_label: 'live booking available',
        cont_prf_uuid: profileAnalytics?.profile?.prf_uuid
      })
    }
  }, [isClinic, name, profileAnalytics?.profile?.prf_uuid, toggleBookingModal])

  const displayTopKeyword = !isClinic && !!topLevelKeywords

  const detailsSpecialistContent = useMemo(
    () => (
      <Box sx={{ marginTop: '14px' }}>
        <ProfileDetails
          yearsOfExperience={isClinic ? null : yearsOfExperienceValue}
          address={transformedAddress}
          addressTotal={addressTotal}
          connections={isClinic ? null : formattedConnectionsCount}
          endorsementsFrom={isClinic ? null : recommendersString}
          isBookingAvailable={!isClinic && isBookingAvailable}
          toggleBookingModal={onToggleBookingModal}
        />
      </Box>
    ),
    [
      addressTotal,
      formattedConnectionsCount,
      isBookingAvailable,
      isClinic,
      onToggleBookingModal,
      recommendersString,
      transformedAddress,
      yearsOfExperienceValue
    ]
  )

  const detailsPracticeContent = useMemo(
    () => (
      <Box sx={{ marginTop: '10px' }}>
        <ProfileDetails
          address={transformedAddress}
          addressTotal={addressTotal}
          workingTime={workingTimeString}
          specialties={specialtiesList}
          specialtiesTotal={specialtiesCount}
          specialistsTotal={specialistsCount}
          followers={followersCount}
        />
      </Box>
    ),
    [
      addressTotal,
      followersCount,
      specialistsCount,
      specialtiesCount,
      specialtiesList,
      transformedAddress,
      workingTimeString
    ]
  )

  const mainFontColor = '#051851'

  return (
    <>
      <Grid
        container
        alignItems="center"
        wrap="nowrap"
        sx={{ pb: { xs: hideRating ? '22px' : '8px', sm: '32px' }, gap: { sm: '20px', xs: '10px' } }}
      >
        <Avatar
          handleOpenVideoPlayer={handleOpenVideoPlayer}
          introVideoAvailable={introVideoAvailable}
          isEntryOrUnclaimed={isBasicView}
          name={name}
          image={image}
          title={userTitle}
          variant={AvatarVariants.VARIANT2}
        />
        <Grid container xs={7.4} direction="column" justifyContent="center">
          <Typography
            variant="h2"
            component="h1"
            sx={{
              fontSize: '24px',
              fontWeight: 700,
              color: mainFontColor,
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              gap: '3px',
              lineHeight: '20px',
              marginRight: '3px'
            }}
            data-testid={TEST_IDS.PROFILE_INTRO_AVATAR}
          >
            <Box>
              <span style={{ lineHeight: '32.04px', marginRight: '3px' }}>{userTitle}</span>
              <Box display="inline-block">
                {isUnclaimed ? (
                  <WhiteTooltip
                    isRtl={isRtl}
                    sx={{
                      '& .MuiTooltip-tooltip': {
                        height: 'auto',
                        width: 'auto'
                      }
                    }}
                    title={translate('profile.unverified')}
                  >
                    <Box display="inline">
                      <UnverifiedCurvedIcon
                        sx={{
                          display: 'inline-block',
                          verticalAlign: 'middle',
                          width: '18px',
                          height: '18px',
                          marginTop: { md: '-1px', xs: '3px' },
                          marginRight: '3px'
                        }}
                      />
                    </Box>
                  </WhiteTooltip>
                ) : (
                  <WhiteTooltip
                    isRtl={isRtl}
                    sx={{
                      '& .MuiTooltip-tooltip': {
                        height: 'auto',
                        width: 'auto'
                      }
                    }}
                    title={translate('profile.verified')}
                  >
                    <Box display="inline">
                      <VerifiedCurvedIcon
                        sx={{
                          display: 'inline-block',
                          verticalAlign: 'middle',
                          width: '20px',
                          height: '22px',
                          marginTop: { md: '4px', xs: '7px' },
                          marginRight: '3px'
                        }}
                      />
                    </Box>
                  </WhiteTooltip>
                )}
                <Box component={Hidden} smUp implementation="css" display="contents">
                  <Typography display="inline" sx={{ fontSize: '13px', fontWeight: 500 }} color={mainFontColor}>
                    {suffix}
                  </Typography>
                </Box>
                <Box component={Hidden} smDown implementation="css" display="contents">
                  <TopKeywordText display="inline" sx={{ fontSize: '15px', color: mainFontColor, paddingTop: '4px' }}>
                    {suffix}
                  </TopKeywordText>
                </Box>
              </Box>
            </Box>
          </Typography>

          {displayTopKeyword && (
            <TopKeywordText sx={{ mt: { sm: '6px' }, fontSize: '18px', color: mainFontColor, lineHeight: '26px' }}>
              {topLevelKeywords}
            </TopKeywordText>
          )}

          <Box sx={{ marginLeft: '-8px' }}>
            {isClinic ? (
              <Hidden implementation="css" smDown>
                {detailsPracticeContent}
              </Hidden>
            ) : (
              <Hidden implementation="css" smDown>
                {detailsSpecialistContent}
              </Hidden>
            )}
          </Box>
        </Grid>
      </Grid>
      <Hidden implementation="css" smUp>
        <Box sx={{ pb: '14px' }}>
          {hideRating ? null : (
            <Box
              sx={{
                p: '10px',
                borderTop: `1px solid ${theme.palette.grey[600]}`,
                borderBottom: `1px solid ${theme.palette.grey[600]}`,
                mt: '14px',
                pb: '14px'
              }}
            >
              {isBasicView ? (
                <StarsComponent averageRating={0} review={null} variant={StarsVariants.minimal} />
              ) : (
                <StarsComponent
                  averageRating={review?.averageRating ?? 0}
                  review={review}
                  variant={StarsVariants.minimal}
                />
              )}
            </Box>
          )}
          {isClinic ? detailsPracticeContent : detailsSpecialistContent}
        </Box>
      </Hidden>
    </>
  )
}

export default Minimal
