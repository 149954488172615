import React from 'react'
import { Box, ExpandMoreIcon, Rating, useMediaQuery, useTheme } from '@hermes/web-components'
import ReviewStatisticRow from '../../../profiles/Reviews/ReviewStatisticRow'
import useTranslateMessage from '../../../../hooks/useTranslateMessage'
import { StarsComponentProps } from './types'

const Default = ({ expanded, averageRating, review, toggleExpandedContent }: StarsComponentProps) => {
  const theme = useTheme()
  const isLessThenLg = useMediaQuery(theme.breakpoints.down('lg'))
  const translate = useTranslateMessage()

  return (
    <Box pb={2}>
      <Box display="flex" sx={{ px: { lg: 2, xs: 0 }, mt: 1, mb: expanded ? 0 : 1 }} alignItems="center">
        <Rating value={averageRating} sx={{ fontSize: 24 }} readOnly />
        <ExpandMoreIcon
          sx={!expanded ? { transform: 'rotate(180deg)' } : {}}
          fontSize="inherit"
          onClick={toggleExpandedContent}
        />
      </Box>
      {!expanded && (
        <Box>
          <ReviewStatisticRow
            rating={review?.overallExperience}
            name={translate('reviews.overallExperience')}
            noSpaceBefore={isLessThenLg}
          />
          <ReviewStatisticRow
            rating={review?.bedsideManner}
            name={translate('reviews.bedsideManner')}
            noSpaceBefore={isLessThenLg}
          />
          <ReviewStatisticRow
            rating={review?.explanation}
            name={translate('reviews.explanation')}
            noSpaceBefore={isLessThenLg}
          />
          <ReviewStatisticRow
            rating={review?.cleanliness}
            name={translate('reviews.cleanliness')}
            noSpaceBefore={isLessThenLg}
          />
          <ReviewStatisticRow
            rating={review?.friendliness}
            name={translate('reviews.friendliness')}
            noSpaceBefore={isLessThenLg}
          />
          <ReviewStatisticRow
            rating={review?.waitTime}
            name={translate('reviews.wait_time')}
            noSpaceBefore={isLessThenLg}
          />
          <ReviewStatisticRow
            rating={review?.facilities}
            name={translate('reviews.facilities')}
            noSpaceBefore={isLessThenLg}
          />
          <ReviewStatisticRow
            rating={review?.careAndSupport}
            name={translate('reviews.careAndSupport')}
            noSpaceBefore={isLessThenLg}
          />
          <ReviewStatisticRow rating={review?.ease} name={translate('reviews.easy')} noSpaceBefore={isLessThenLg} />
          <ReviewStatisticRow
            rating={review?.quality}
            name={translate('reviews.quality')}
            noSpaceBefore={isLessThenLg}
          />
          <ReviewStatisticRow rating={review?.impact} name={translate('reviews.impact')} noSpaceBefore={isLessThenLg} />
        </Box>
      )}
    </Box>
  )
}

export default Default
